import { JObject } from "../../../../common/data/models";
import { DateUtils } from "../../../../helpers/date";
import { PostDto } from "../../domain/dtos";
import { Post } from "../models";

export const PostMapper = {
    /**
     * Create post model from json object
     * @param json
     * @returns {Post}
     */
    fromJson: (json: JObject): Post => {
        return {
            postId: json.postId,
            partnerId: json.partnerId,
            partnerName: json.partnerName,
            titleAr: json.titleAr,
            titleEn: json.titleEn,
            descriptionAr: json.descriptionAr,
            descriptionEn: json.descriptionEn,
            startDate: DateUtils.fromISO(new Date(json?.startDate).toISOString()),
            endDate: DateUtils.fromISO(new Date(json?.endDate).toISOString()),
            businessType: json.businessType,
            businessName: json.businessName,
            priority: json.priority,
            extraItems: json.extra ? json.extra.map((item:any) =>{
                return {
                    fileId: item.value,
                    titleEn: item.titleEn,
                    titleAr: item.titleAr
                }
            }) : []
        }
    },

    /**
         *
         */
    toJson: (post: Post): JObject => {
        console.log("tojson",post);
        return { ...post, extra:  post.extraItems ? post.extraItems?.map((item:any) =>{
            return {
                value: item.fileId,
                titleEn: item.titleEn,
                titleAr: item.titleAr,
                type: "IMAGE"
            }
        }) : []}
    },

    /**
        * Create model from json object
        * @param dto
        * @returns {Post}
        */
    fromDto: (dto: PostDto): Post => {
        return {
            postId: dto.postId,
            partnerId: dto.partner?.partnerId,
            partnerName: dto.partner?.partnerName,
            titleAr: dto.titleAr,
            titleEn: dto.titleEn,
            descriptionAr: dto.descriptionAr,
            descriptionEn: dto.descriptionEn,
            startDate: dto.startDate,
            endDate: dto.endDate,
            businessType: dto.businessType,
            businessName: dto.businessName,
            priority: dto.priority,
            extraItems: dto.extraItems ? dto.extraItems?.map((item:any) =>{
                return {
                    fileId: item.fileId,
                    titleEn: item.titleEn,
                    titleAr: item.titleAr
                }
            }) : []
        }
    },


    /**
         *
         */
    toDto: (model: Post): PostDto => {
        return {
            postId: model.postId,
            partner: {
                partnerId: model.partnerId,
                partnerName: model.partnerName
            },
            titleAr: model.titleAr,
            titleEn: model.titleEn,
            descriptionAr: model.descriptionAr,
            descriptionEn: model.descriptionEn,
            startDate: model.startDate,
            endDate: model.endDate,
            businessType: model.businessType,
            businessName: model.businessName,
            priority: model.priority,
            extraItems: model.extraItems? model.extraItems.map((item:any) =>{
                return {
                    fileId: item.fileId,
                    titleEn: item.titleEn,
                    titleAr: item.titleAr
                }
            }) : []
        }
    }
}
import {TawreedMessageCountDto, TawreedMessageDto} from "../../domain";
import {tawreedHttpService} from "../../../../http";
import {endpoints} from "../../../../constants/endpoints";
import {buildSortParamsToString, Result} from "../../../../common/pagination";
import {JObject} from "../../../../common/data/models";
import {MessageCountMapper, MessageMapper} from "../mappers";
import {DateUtils} from "../../../../helpers/date";

export class MessagesRepository {

    public getCount(): Promise<number> {
        return tawreedHttpService.post<boolean, number>(endpoints.NOTIFICATIONS_MESSAGES_COUNT, true, undefined, undefined, 'none')
            .then(res => res.data ?? 0)
            .catch(() => {
                return 0;
            });
    }

    /***
     *
     */
    public getMessages(page: number): Promise<Result<TawreedMessageDto>> {
        const params = {
            page: page,
            size: 10,
        };
        const data = {};

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.NOTIFICATIONS_MESSAGES_ALL, data, params, undefined, "error")
            .then((res) => {
                return {
                    ...res.data!,
                    content: res!.data!.content.map(e => MessageMapper.fromJson(e)),
                }
            });
    }

    public markAsRead(message: TawreedMessageDto): Promise<TawreedMessageDto> {
        return tawreedHttpService.post<number, void>(endpoints.NOTIFICATIONS_MESSAGES_MARK_AS_READ, message.messageId, undefined, undefined, 'none')
            .then(() => {
                message.readDate = DateUtils.now();
                message.unread = false;
                return message;
            }).catch(() => {
                return message;
            });
    }
    

    public getCounts(): Promise<TawreedMessageCountDto[]> {
        return tawreedHttpService.post<boolean, JObject>(endpoints.NOTIFICATIONS_MESSAGES_TYPE_COUNT, true, undefined, undefined, 'none')
            .then(res => {
                return res.data!.map((e:JObject) => MessageCountMapper.fromJson(e))
            })
            .catch(() => {
                return [];
            });
    }

    public getMessagesByType(types:string[], page: number): Promise<Result<TawreedMessageDto>> {
        const params = {
            sort: buildSortParamsToString({
                 sortField: "sendDate",
                    sortOrder: -1,
                    multiSortMeta: undefined
            }),
            page: page,
            size: 10,
        };
        const data = {
            types: types
        };

        return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.NOTIFICATIONS_MESSAGES_TYPE_ALL, data, params, undefined, "error")
            .then((res) => {
                return {
                    ...res.data!,
                    content: res!.data!.content.map(e => MessageMapper.fromJson(e)),
                }
            });
    }
}

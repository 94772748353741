import React from "react";
import {messagesService, TawreedMessageDto} from "../../../domain";
import { Result} from "../../../../../common/pagination";
import {DataView} from "primereact/dataview";
import { NotificationsDropdownItem } from "./notification-list-item";

export interface NotificationsListProps {
    types: string[];
    onItemClick: () => Promise<void>;
}

export const NotificationsDropdownList: React.FC<NotificationsListProps> = ({types, onItemClick}) => {

    // state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [params, setParams] = React.useState<{ filter: 'all' | 'unread'; page: number }>({
        page: 0,
        filter: 'all',
    });
    const [notifications, setNotifications] = React.useState<Result<TawreedMessageDto>>();

    React.useEffect(
        () => {
            setLoading(true);
            messagesService.getMessagesByType(types, params.page)
                .then(res => {
                    setLoading(false);
                    setNotifications(res);
                })
                .catch(() => {
                    setLoading(false);
                });
        },
        [types]);

    const onMarkAsRead = (notification: TawreedMessageDto) => {
        if (notification.unread) {
            return messagesService.markAsRead(notification)
                .then(res => {
                    const next: Result<TawreedMessageDto> = {...notifications!};
                    if (next && next.content) {
                        const index = next.content.findIndex(e => e.messageId === notification.messageId);
                        if (index >= 0 && index < next.content.length) {
                            next.content[index] = res;
                            setNotifications(next);
                        }
                    }
                });
        }
        return Promise.resolve();
    }
    
    return (
        <DataView style={{ maxHeight: '35rem', overflowY: 'auto'}}  value={notifications?.content}
                  layout="list"
                  itemTemplate={(e) => <NotificationsDropdownItem 
                    onItemClick={()=>onItemClick()} notification={e} onMarkAsRead={() => onMarkAsRead(e)}/>}
                  lazy
                  rows={notifications?.numberOfElements} 
                  totalRecords={notifications?.totalElements} 
                  first={notifications?.pageable.offset}
                  loading={loading}/>)
}

import React, { useState } from "react";
import { TawreedTable, TawreedTableColumnProps, TawreedTableProps } from "../../../../../common/components/table";
import { OrderItemDto, orderService as service } from "../../domain";
import { TawreedTableEditor } from "../../../../../common/components/table/editors";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { tawreedTranslationService } from "../../../../../common/translations";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedActionName } from "../../../../../common/actions";
import { AuthUtils } from "../../../../auth/domain";
import { GlobalContext } from "../../../../../context";
import { IBonusOperations, IBonusType } from "../../../../constants";
import { OrderItemUpdateDialog } from "./order-form/order-item-update-dialog";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { OrderItemChangeBonusDialog } from "./order-item-change-bonus-dialog";

export type OrderFormItemsProps = {
    // controls
    className: string;
    // data
    showEdit?: boolean;
    showNote: boolean;
    orderId?:number;
    reload?: boolean;
    // actions
    onSave: (item: OrderItemDto) => Promise<boolean>;

    returned: boolean | undefined;
};

export const OrderFormItems: React.FC<OrderFormItemsProps> = (props) => {

    const { auth:{user}, constants: { constants } } = React.useContext(GlobalContext);
    const [updateItemDialog,setUpdateItemDialog] = useState<boolean>(false);
    const [updateItemBonusDialog, setUpdateItemBonusDialog] = useState<boolean>(false);

    console.log("props.showEdit",props.showEdit);
    const [selectedItem, setSelectedItem] = useState<OrderItemDto>();
    const lang = AuthUtils.current().language;
    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return tawreedTranslationService.translate("lbl_unit");
        if (discount === 'percentage')
            return "%"

        return "%+"
    }
    let columns: TawreedTableColumnProps[] =props.returned ?
    [
        {
            dataType: 'text',
            field: "productName",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_product_name",
            style: CssUtils.width('20%'),
            bodyStyle: { 'unicode-bidi': 'plaintext' }
        },
        {
            dataType: 'numeric',
            field: "quantity",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_quantity",
            body: props.showEdit ? TawreedTableEditor.numeric('int', 'quantityOrdered', 0, !AuthUtils.current().authorized(TawreedActionName.OrdersEditQuantity)) : undefined,
            style: CssUtils.width('20%'),
        },
        {
            dataType: 'numeric',
            field: "retailPrice",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_retail_price",
            style: CssUtils.width('20%'),
        },
        {
            dataType: 'numeric',
            field: "discountPercent",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_discount_percent",
            style: CssUtils.width('20%'),
        },
        {
            field: "itemTotal",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_item_total",
            ...TawreedTableFormatters.decimal("itemTotal", user?.scale! ,CssUtils.width('20%')),
        },
    ]
    :
    [
        {
            dataType: 'text',
            field: "storeItemId",
            sortable:true,
            header: "lbl_store_product_item_id",
            style: CssUtils.width('12%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            bodyStyle: { 'unicode-bidi': 'plaintext' }
        },
        {
            dataType: 'text',
            field: "productName",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_product_name",
            style: CssUtils.width('20%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') ,
            bodyStyle: { 'unicode-bidi': 'plaintext' }
        },
        {
            dataType: 'numeric',
            field: "quantityOrdered",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_quantity_ordered",
            style: CssUtils.width('8%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            dataType: 'numeric',
            field: "quantity",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_quantity",
            style: CssUtils.width('8%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            field: "retailPrice",
            sortable:true,
            header: "lbl_sales_cart_store_cart_items_retail_price",
            ...TawreedTableFormatters.decimal("retailPrice", user?.scale!),
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            field: "discountValue",
            header: "lbl_base_discount",
            sortable:true,
            ...TawreedTableFormatters.currencyOrPercentageDepend("discountValue", 'discountType', constants?.info?.currencyCode ?? '', user?.scale!),
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            dataType: 'numeric',
            field: "discountBonus",
            header: "lbl_additional_discount",
            style: CssUtils.width('8%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            dataType: 'numeric',
            field: "bonus",
            header: "lbl_store_products_bonus",
            style: CssUtils.width('8%'),
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            dataType: 'numeric',
            sortable: false,
            field: "bonus",
            header: "lbl_store_products_applied_bonus",
            style: CssUtils.width('15%'),
            body: props.showEdit ? (options: any) => (
                <React.Fragment>
                            {
                                options.appliedBonus && options.appliedBonus.length > 0 &&
                                !props.showEdit &&
                                <div className="bonuses">
                                    {options.appliedBonus.map((e: any) => {
                                        if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                            return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                        else
                                            return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                    })
                                    }
                                </div>
                            }
                            {
                                options.appliedBonus && options.appliedBonus.length > 0 &&
                                props.showEdit &&
                                <div className="bonuses">
                                    {options.appliedBonus.map((e: any) => {
                                        if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                            return (
                                                <div className="">
                                                    <Button type="button" label={`${e.quantity} + ${e.bonus}`} onClick={() => {
                                                        setSelectedItem(options);
                                                        setUpdateItemBonusDialog(true);
                                                    }
                                                    } className="p-button-rounded p-button-text p-button-danger bonuses-item" />
                                                </div>)
                                        else
                                            return <div className="">
                                                <Button type="button"
                                                    label={`${e.quantity} ${e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  ${e.bonus} ${getBox(e.discountType!)}`}
                                                    onClick={() => {
                                                        setSelectedItem(options);
                                                        setUpdateItemBonusDialog(true);
                                                    }
                                                    } className="p-button-text p-button-danger bonuses-item" />
                                            </div>
                                    })
                                    }
                                </div>
                            }

                            {
                                (!options.appliedBonus || options.appliedBonus.length === 0) &&
                                <Button style={{ minWidth: "80px", justifyContent: 'center' }}  type="button" label={tawreedTranslationService.translate("lbl_add_bonus")} onClick={() => {
                                    setSelectedItem(options);
                                    setUpdateItemBonusDialog(true);
                                }
                                } className="p-button-rounded p-button-text p-button-danger" />
                            }
                        </React.Fragment>
                    ):undefined
        },
        {
            field: "storeProductTax",
            sortable:true,
            header: "lbl_salesordersitem_totaltax",
            style: CssUtils.width('8%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
        {
            field: "itemTotal",
            sortable:true,
            header: "lbl_sales_orders_o_info_items_item_total",
            ...TawreedTableFormatters.decimal("itemTotal", user?.scale!),
            style: CssUtils.width('10%'),
            className: CssUtils.className.field.amount,
            headerStyle: lang === "en" ? CssUtils.align('left') : CssUtils.align('right') 
        },
    ];
    if (props.showEdit){
        columns = [...columns, {
            field: "delete",
            className: CssUtils.className.field.amount,
            style: CssUtils.width('2%'),
            body: (options: any) => {
                return (
                    <Button type="button" icon={PrimeIcons.PENCIL} onClick={() => 
                        {
                            setSelectedItem(options);
                            setUpdateItemDialog(true);
                        }
                    } className="p-button-rounded p-button-text p-button-danger" />
                )
            }
        }];
    }
    const tawreed: TawreedTableProps = {
        ds: {
            mode: 'lazy',
            onSearch: (q) =>service.searchItems(props.orderId!,q),
        },
        reload: props.reload,
        pagination: true,
        
        name: 'tbl.sales.orders.order.items',
        dataKey: 'orderItemId',
        columns: columns,
        editable: props.showEdit ? { onEditComplete: (newRowData, _field, _value) => props.onSave(newRowData) } : undefined,
    };

    return (<div className={props.className}>
        <TawreedTable {...tawreed} />
        <OrderItemUpdateDialog
                onHide={() => { setUpdateItemDialog(false) }}
                onSave={(data) => {
                   props.onSave(data)
                }}
                item={selectedItem!}
                
                visible={updateItemDialog} />
        <OrderItemChangeBonusDialog
                hide={() => { setUpdateItemBonusDialog(false) }}
                accept={(data) => {
                    var item = selectedItem!;
                    if (data) {
                        item.appliedBonus = [{ ...data, uniqueId: '', bonusId: -1, bonusType: data.bonusType! }];
                    }
                    else {
                        item.appliedBonus = [];
                    }
                    props.onSave(selectedItem!)
                }}
                singlePrice={selectedItem?.sellingPrice}
                visible={updateItemBonusDialog}
                appliedBonus={selectedItem?.appliedBonus && selectedItem?.appliedBonus.length > 0
                    ? selectedItem?.appliedBonus.at(0) :
                    undefined}
            />
    </div>);
}

import React from "react"
import { PrimeIcons } from "primereact/api"
import { Panel } from "primereact/panel"
import { useHistory, useParams } from "react-router-dom"
import { PostDto, PostService } from "../../domain"
import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputText, TawreedInputTextarea } from "../../../../common"
import { TawreedAction, TawreedActionName } from "../../../../common/actions"
import { tawreedTranslationService } from "../../../../common/translations"
import { PartnerAutoCompleteComponent } from "../../../partners"
import { GlobalContext } from "../../../../context"
import { PartnerType } from "../../../partners/data"
import { Routes } from "../../../../router"
import { PostImageTable } from "./post-Image-table"
import { FileService, TawreedFile } from "../../../upload"


export const PostForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service = React.useMemo(() => new PostService(), []);


    const [loading, setLoading] = React.useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<PostDto>({
    });
    const [actions, setActions] = React.useState<TawreedAction[]>([]);
    const fileService: FileService = React.useMemo(() => new FileService(), []);

    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);

    React.useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {

                setLoading(true);
                setMode('Edit');
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData({
                    partner: {
                        partnerId: user!.partnerId
                    }
                });
            }
        }

        return () => {
            mounted = false;
        };
    }, [id, service]);

    const onCreateOrSave = (data: PostDto, cb: TawreedFormSubmitCallback<PostDto>): Promise<void> => {
        setLoading(true);
        return service.createOrUpdate(data)
            .then((res) => {
                if (mode === 'Create') {
                    history.replace(Routes.PostsDetails.replace(':id', res.postId ? res.postId.toString() : "0"));
                }
                setLoading(false);
                setData(res);
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<PostDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<PostDto>(TawreedActionName.PostSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave,' p-button-text ml-1'),
    };

    const addFile = (fileData: { file: TawreedFile, titleEn: string, titleAr: string }) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const key = fileService.upload(fileData.file).then((res) => {
                var files = data.extraItems ? [...data.extraItems] : [];
                files.push({
                    fileId: res,
                    titleEn: fileData.titleEn,
                    titleAr: fileData.titleAr
                });
                setData({ ...data, extraItems: [...files] });
                setLoading(false);
            }).catch(()=>{
                setLoading(false);
            });
        });
    }
    const downloadOrderFile = async (fileId: string, name?: string) => {
        try {
            //setState({ ...state, loading: true });

            setLoading(true);
            await fileService.downloadS3(fileId, name + '.' + fileId.split('.').at(1)!);

        } catch (err) {
            console.log(err);
        }
        setLoading(false);

    }
    const deleteFile = async (fileId: string) => {
        var files = data.extraItems ? [...data.extraItems] : [];
        files = files.filter(x => x.fileId !== fileId);
        setData({ ...data, extraItems: [...files] });

    }

    React.useEffect(
        () => {
            const onDelete = () => {
                setLoading(true);
                return service.deleteAll([data!.postId!]).then(res => {
                    setLoading(false);
                    history.replace(Routes.PostsSearch);
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }
            const onView = (): Promise<void> => {
                return new Promise((resolve, reject) => {
                    history.push(Routes.PostView.replace(':id', data!.postId!.toString()));
                });
            }
            const next = [
            ];
            if (data && data.postId) {
                next.push(new TawreedAction(TawreedActionName.PostDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger p-button-text ml-1', true));
                next.push(new TawreedAction(TawreedActionName.PostDetails, 'statefull', 'lbl_view', PrimeIcons.EYE, onView, 'p-button-success p-button-text ml-1'));
                
            }
            setActions(next);
        },
        [data, service, history]);


    return (
        <TawreedForm title="lbl_posts"
            dataKey="postId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            actions={actions} useBack=".." useReset={false} className="grid form-grid">

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">

                    {/* <TawreedFormField name="active" title="lbl_payment_partner_active" className="field-checkbox col-12 md:col-6" flip>
                        <TawreedInputSwitch name="active" render="form" />
                    </TawreedFormField> */}
                    <TawreedFormField name="titleAr" className="field col-12 md:col-6" title="lbl_posts_title_ar">
                        <TawreedInputText name="titleAr" maxLength={200} render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="titleEn" className="field col-12 md:col-6" title="lbl_posts_title_en">
                        <TawreedInputText name="titleEn" maxLength={200} render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="descriptionAr" className="field col-12 md:col-6" title="lbl_posts_description_ar">
                        <TawreedInputTextarea dir="rtl" maxLength={1000} name="descriptionAr" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="descriptionEn" className="field col-12 md:col-6" title="lbl_posts_description_en">
                        <TawreedInputTextarea dir="ltr" maxLength={1000} name="descriptionEn" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="startDate" className="field col-12 md:col-6" title="lbl_posts_start_date">
                        <TawreedCalendar name="startDate" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField name="endDate" className="field col-12 md:col-6" title="lbl_posts_end_date">
                        <TawreedCalendar name="endDate" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    
                    <TawreedFormField name="priority" className="field col-12 md:col-6" title="lbl_posts_priority">
                        <TawreedInputNumber name="priority" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                </div>
            </Panel>

            <Panel header={tawreedTranslationService.translate('lbl_posts_images')} className="col-12">
            <PostImageTable data={data.extraItems ?? []}
                    addDocumentFile={addFile} downloadDocumentFile={downloadOrderFile} delelteFile={deleteFile}
                    loading={loading} />
            </Panel>
            

        </TawreedForm>
    )
}

import {MessagesRepository} from "../../data";
import {TawreedMessageCountDto, TawreedMessageDto} from "../dtos";
import {Result} from "../../../../common/pagination";

export type MessagesCountCallback = (count: number) => void;
export type MessagesCountsCallback = (count: TawreedMessageCountDto[]) => void;
export type MessagesCountUnsubscribe = () => void;

class MessagesService {

    private readonly repository = new MessagesRepository();
    private callbacks: Array<MessagesCountCallback> = [];
    private countsCallbacks: Array<MessagesCountsCallback> = [];
    private count = -1;
    private counts: Array<TawreedMessageCountDto> = [];

    public setCount(value: number): void {
        this.count = value;
        this.callbacks.forEach(cb => cb(this.count));
    }

    public setCounts(values: TawreedMessageCountDto[]): void {
        this.counts = values;
        this.countsCallbacks.forEach(cb => cb(this.counts));
    }

    public updateCount() : void{
        this.repository.getCount().then(res => this.setCount(res));
    }

    public updateCounts() : void{
        this.repository.getCounts().then(res => this.setCounts(res));
    }

    public getCount(cb: MessagesCountCallback): MessagesCountUnsubscribe {
        if (this.count === -1) {
            this.repository.getCount().then(res => this.setCount(res));
        }
        cb(this.count);
        const index = this.callbacks.length;
        this.callbacks.push(cb);
        return () => {
            this.callbacks.splice(index, 1);
        }
    }

    public getCounts(cb: MessagesCountsCallback): MessagesCountUnsubscribe {
        
        this.repository.getCounts().then(res => this.setCounts(res));
        cb(this.counts);
        const index = this.countsCallbacks.length;
        this.countsCallbacks.push(cb);
        return () => {
            this.countsCallbacks.splice(index, 1);
        }
    }

    public async getMessages(page: number = 0): Promise<Result<TawreedMessageDto>> {
        if (!page) {
            page = 0;
        }
        if (page === 0) {
            this.setCount(0);
        }
        return this.repository.getMessages(page);
    }

    public markAsRead(message: TawreedMessageDto): Promise<TawreedMessageDto> {
        if (!message) {
            return Promise.reject();
        }
        return this.repository.markAsRead(message);
    }

    public async getMessagesByType(types:string[] ,page: number = 0): Promise<Result<TawreedMessageDto>> {
        if (!page) {
            page = 0;
        }
        if (page === 0) {
            this.setCount(0);
        }
        return this.repository.getMessagesByType(types,page);
    }
}

export const messagesService = new MessagesService();


import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState, useEffect } from 'react'
import { tawreedTranslationService } from '../../../../common/translations';
import { TawreedFormField, TawreedInputFile, TawreedInputText } from '../../../../common';
import { TawreedFile } from '../../../upload';

export interface PostImageUploadDialogProps {
    visible: boolean;

    hide: () => void;

    accept: (data: { file: File, titleEn: string, titleAr: string }) => void;

}

export const PostImageUploadDialog: FC<PostImageUploadDialogProps> = ({ visible, hide, accept }) => {


    const [dialogState, setDialogState] = useState<{
        file: File | undefined,
        titleEn: string | undefined,
        titleAr: string | undefined,
    }>({
        file: undefined,
        titleEn: undefined,
        titleAr: undefined,
    });


    useEffect(() => {

        setDialogState({
            file: undefined,
            titleEn: undefined,
            titleAr: undefined,
        })
    }, [visible])


    const PostImageUploadDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" /*disabled={dialogState.loading}*/ />
            <Button onClick={() => {
                accept({
                    file: dialogState.file!,
                    titleEn: dialogState.titleEn!,
                    titleAr: dialogState.titleAr!,
                });
                hide();
            }} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={!dialogState || !dialogState.file || !dialogState.titleEn|| !dialogState.titleAr}
            // loading={dialogState.loading}
            />
        </div>);
    }

    return (
        <Dialog style={{}} header={tawreedTranslationService.translate('lbl_posts_images_add_image')} visible={visible} footer={<PostImageUploadDialogFooter />} onHide={hide}
         draggable={false} position="top" closable={true} closeOnEscape={true}>
            <form className="formgrid grid" >
                <React.Fragment>
                    <TawreedFormField title="lbl_posts_images_image" className="field col-12" name="file">
                        <TawreedInputFile render='standalone'  name="file" accept='image/*' onChange={
                            (e: TawreedFile | undefined) => setDialogState({ ...dialogState, file: e && e.value instanceof File ? e.value : dialogState.file })
                        } />
                    </TawreedFormField>
                    <TawreedFormField name="titleEn" title="lbl_posts_images_titleEn" className="field col-12 md:col-12 mt-3">
                        <TawreedInputText dir="ltr" value={dialogState?.titleEn} name="titleEn" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, titleEn: e.target.value });
                        }} />

                    </TawreedFormField>
                    <TawreedFormField name="titleAr" title="lbl_posts_images_titleAr" className="field col-12 md:col-12 mt-3">
                        <TawreedInputText dir="rtl" value={dialogState?.titleAr} name="titleAr" rules={{ required: "msg_field_is_required" }} render="standalone" onChange={(e) => {
                            setDialogState({ ...dialogState, titleAr: e.target.value });
                        }} />

                    </TawreedFormField>

                </React.Fragment>
            </form>
        </Dialog>
    )
}

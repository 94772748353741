import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { StoreProduct } from "../../../../catalog/store-products/data";
import { PartnerDto } from "../../../../partners/domain";
import { Dialog } from "primereact/dialog";
import { priceListDto, StoreProductsService } from "../../../../catalog/store-products/domain";
import { AuthUtils } from "../../../../auth/domain";
import { CssUtils } from "../../../../../helpers/css";
import { TawreedDropdown, TawreedFormField, TawreedTable, TawreedTableProps } from "../../../../../common";
import { ObjectUtils } from "../../../../../helpers/object";
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { QuantityForm } from "./quantity-form";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PriceListServcie } from "../../../../catalog/store-products/domain/services/price-list.service";
import { GlobalContext } from "../../../../../context";
import { IBonusOperations, IBonusType } from "../../../../constants";
import { Tooltip } from "primereact/tooltip";
export interface CartFormProductsState {
    storeProduct?: StoreProduct;
}

export interface ProductsDialogProps {
    visible: boolean;
    // storeProduct: StoreProduct;
    // store: string,
    // accept: () => void;
    // changeQuantity: (quantity: number, bonusType: string) => void;
    className?: string;
    onSelect: (p: StoreProduct) => void;
    disabled: boolean;
    zoneId: number;
    actor: PartnerDto;
    store: string,
    priceListId?: any;
    customerId: number,
    changeQuantity: (quantity: number, bonusType: string) => void;
    hide: () => void;
    refs: any;
}
export interface StorePriceSearchState {

    stores: Array<PartnerDto>;
    priceScheme: number;
}

export const ProductsDialog: FC<ProductsDialogProps> = ({ visible, className, onSelect, disabled, zoneId, actor, customerId, changeQuantity, store, priceListId, hide, refs }) => {

    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);

    const [tabIndex, setTabIndex] = useState<number>(0);

    const [state, setState] = useState<CartFormProductsState>({
        storeProduct: undefined,
    });

    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);
    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    const getBox = (discount: string) => {
        if (discount === 'fixed')
            return tawreedTranslationService.translate("lbl_unit");
        if (discount === 'percentage')
            return "%"

        return "%+"
    }

    useEffect(() => {
        if (priceLists.length == 0) {
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
        }
    }, [visible]);

    const [reload, setReload] = useState<boolean>(false);
    const [search, setSearch] = useState<Partial<StorePriceSearchState>>({});
    var keyword: string | undefined = undefined;
    const rowClass = (data:any): object | string => {
        return {
            'bg-primary-reverse': data.tags && data.tags.filter((tag:any)=>tag.tagId ===1).length >0 
        };
    }
    const { auth: { user } } = React.useContext(GlobalContext);
    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        //rowClassName: rowClass,
        ds: {
            mode: 'lazy',
            asyncSearch: true,
            onSearch: (q) => service.searchSimilarWithPrice({
                q: q.keyword ?? '', zoneId: zoneId,
                actor: actor, priceListId: priceListId ? priceListId : search.priceScheme, customerId: customerId
            },
                { ...q, page: reload || keyword !== q.keyword ? 0 : q.page }).then((res) => {
                    return res;
                }).catch().finally(() => { keyword = q.keyword; setReload(false) })
        },
        reload: reload,
        dataKey: 'storeProductId',
        name: 'tbl.store.products',
        columns: [
            {
                field: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                header: "lbl_store_products_product_id",
                sortable: true,
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                filter: false,
                filterField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                style: { ...CssUtils.width('15%') },
                bodyStyle: { 'unicode-bidi': 'plaintext' },
                body: (options: any) => {
                    return (
                        <div className='grid'>
                            <span className='col-10'>{AuthUtils.current().language === 'en' ?
                             options.titleEn : options.titleAr}</span>
                            {options.tags && options.tags.filter((tag: any) => tag.tagId === 1).length > 0 &&
                                <React.Fragment>
                                    <Tooltip target=".logo" mouseTrack mouseTrackLeft={10} />
                                    <img className='logo mt-2' data-pr-tooltip={tawreedTranslationService.translate("tags_nearly_expired")} style={{ color: 'red', width: '20px', height: '20px' }} src="/assets/images/alert.png" />
                                </React.Fragment>
                            }
                        </div>)
                }
            },
            {
                field: "store.partnerName",
                header: "lbl_store_products_store_name",
                sortable: true,
                sortField: 'storeName',
                filter: false,
                filterField: 'storeName',
                style: CssUtils.width('10%'),
            },
            {
                field: "expireDate",
                header: "lbl_store_product_expire_date",
                sortable: true,
                sortField: 'expireDate',
                filter: false,
                filterField: 'expireDate',
                body: (options: any) => {

                    return options.expireDate ? new Date(options.expireDate).toDateString().split(' ').splice(1, 3).join(' ') : ''
                },
                style: CssUtils.width('10%'),
            },
            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                sortable: true,
                filter: false,
                style: CssUtils.width('5%'),
            },
            {
                field: "quantity",
                header: "lbl_quantity",
                sortable: true,
                filter: false,
                style: CssUtils.width('5%'),
            },
            {
                field: "sellingPrice",
                header: "lbl_selling_price1",
                sortable: true,
                filter: false,
                ...TawreedTableFormatters.decimal('sellingPrice', user?.scale!, CssUtils.width('10%')),
                className: CssUtils.className.field.amount,
            },
            {
                dataType: "numeric",
                field: "tax",
                header: "lbl_store_products_tax",
                sortable: true,
                filter: false,
                ...TawreedTableFormatters.percentage('tax', user?.scale!),
                style: CssUtils.width('5%'),
                className: CssUtils.className.field.amount,
            },
            {
                field: "tax",
                header: "lbl_bonus",
                sortable: false,
                filter: false,
                style: CssUtils.width('10%'),
                className: CssUtils.className.field.amount,
                body: (options: any) => {
                    if (options.bonuses !== undefined &&
                        options.bonuses.length > 0) {
                        return <div className="bonuses">
                            {options.bonuses.map((e: any) => {
                                if (e.bonusType === IBonusType.EXTRA_ITEMS)
                                    return <div className="bonuses-item">{e.quantity} + {e.bonus}</div>
                                return <></>
                            })
                            }
                        </div>
                    }
                    return <></>;
                },
            },
            {
                field: "tax",
                header: "lbl_Discounts",
                sortable: false,
                filter: false,
                style: CssUtils.width('10%'),
                className: CssUtils.className.field.amount,
                body: (options: any) => {
                    if (options.bonuses !== undefined &&
                        options.bonuses.length > 0) {
                        return <div className="bonuses">
                            {options.bonuses.map((e: any) => {
                                if (e.bonusType === IBonusType.DISCOUNT)
                                    return <div className="bonuses-item">{e.quantity}{e.bonusOperation === IBonusOperations.GREATER_THAN ? " < " : " * "}  {e.bonus} {getBox(e.discountType!)}</div>
                                return <></>
                            })
                            }
                        </div>
                    }
                    return <></>;
                },
            },
            {
                field: "actions",
                // header: "lbl_add_to_cart",
                body: (options: any) => {
                    return <Button
                        type="button"
                        style={{ width: "100%", height: "35px" }}
                        icon={PrimeIcons.SHOPPING_CART}
                        className="p-button-success p-button-outlined"
                        disabled={options.quantity === 0 ? true : false}
                        onClick={() => {
                            setTabIndex(1);
                            onSelect(options);
                            setState(previous => { return { ...previous, isVisible: true, storeProduct: options }; });
                        }} />
                },
                style: CssUtils.width('12%'),
            }

        ],

        sort: {
            sortMode: 'single',
            initial: {
                sortField: AuthUtils.current().language === 'en' ? "titleEn" : "titleAr",
                sortOrder: 1,
                multiSortMeta: undefined,
            },
        },

    }

    const ProductsDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times"
                onClick={() => {
                    setState({ storeProduct: undefined });
                    setTabIndex(0); hide();
                }} className="p-button-text p-button-secondary" />
            <Button onClick={() => {
                if (refs.current && refs.current instanceof HTMLButtonElement) {
                    refs.current.click();
                    setState({ storeProduct: undefined });
                    setTabIndex(0);
                    hide();
                } else {
                    console.warn('ref.current && ref.current instanceof HTMLButtonElement');
                }
            }} className="p-button-text" style={tabIndex === 0 ? { display: 'none' } : {}} label={tawreedTranslationService.translate('lbl_add_to_cart')} icon="pi pi-check" />
        </div>);
    }
    return (

        <Dialog maximizable style={{ minWidth: '55%' }}
            header={<div className="inline-flex align-items-center justify-content-center">
                {tabIndex === 1 && <Button icon={PrimeIcons.ARROW_LEFT} className="p-button-text mr-2" onClick={() => setTabIndex(0)} />}
                <span>{store}</span>
            </div>} visible={visible} footer={<ProductsDialogFooter />} onHide={() => { setTabIndex(0); hide(); setState({ storeProduct: undefined }); }} draggable={false} position="top" closable={true} closeOnEscape={true}>
            <React.Fragment>
                {
                    tabIndex === 0 &&
                    <React.Fragment>
                        <div className="grid">
                            {!priceListId &&
                                <TawreedFormField name="priceScheme" title="lbl_price_scheme_price_list_name" className="field col-6 md:col-6">
                                    <TawreedDropdown disabled={priceListId} options={priceLists} name="priceScheme"
                                        optionLabel="priceListName" optionValue="priceListId" render="standalone" value={search.priceScheme}
                                        onChange={e => { setSearch({ ...search, priceScheme: e.value }); setReload(true); }} />
                                </TawreedFormField>
                            }
                        </div>
                        <TawreedTable {...tawreed} /></React.Fragment>

                }
                {
                    tabIndex === 1 && <QuantityForm
                        customerId={customerId}
                        visible={tabIndex === 1 ? true : false}
                        storeProduct={state.storeProduct!}
                        store={store}
                        changeQuantity={(quantity: number, bonusType: string) => {
                            changeQuantity(quantity, bonusType);
                        }}
                        back={() => setTabIndex(0)}
                    >

                    </QuantityForm>
                }
            </React.Fragment>
        </Dialog>
    )
}

import { JObject } from "../../../../common/data/models";
import { TawreedMessageCountDto } from "../../domain";

export const MessageCountMapper = {

    fromJson(json: JObject): TawreedMessageCountDto {
        return {
            messageCount: json.messageCount,
            typeCode: json.typeCode,
            messageCountText: this.buildCountString(json.messageCount)
        };
    },

    buildCountString(value: number): string {
        let text = '';
        if (!value) {
            text = '';
        } else if (value < 10) {
            text = value.toLocaleString();
        } else {
            text = '+10';
        }
        return text;
    }
}

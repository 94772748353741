import {TawreedMessageDto} from "../../domain";
import {JObject} from "../../../../common/data/models";
import {DateUtils} from "../../../../helpers/date";


export const MessageMapper = {

    fromJson(json: JObject): TawreedMessageDto {
        return {
            messageId: json.messageId,
            subject: json.subject,
            message: json.message,
            typeCode: json.typeCode,
            sendDate: json.sendDate ? DateUtils.fromISO(json.sendDate) : undefined,
            readDate: json.readDate ? DateUtils.fromISO(json.readDate) : undefined,
            recordId: json.recordId,
            click_action: json.click_action,
            unread: !json.readDate,
            partnerId: json.partnerId,
            partnerName: json.partnerName,
            logoContent: json.logoContent,
            timeAgo: json.timeAgo
        };
    },
}

import React from "react"
import { PostDto, PostEtraDto } from "../../domain"
import { Carousel } from 'primereact/carousel';
import { AuthUtils } from "../../../auth/domain"
import { Card } from "primereact/card"
import { DateUtils } from "../../../../helpers/date"
import { Tag } from "primereact/tag"


export type PostViewComponentProps = {
    /**
     *
     */
    rowData: PostDto;
}
export const PostViewComponent: React.FC<PostViewComponentProps> = ({rowData}) => {

    const [data, setData] = React.useState<PostDto>(rowData);

    React.useEffect(() => {setData(rowData)},[rowData]);


    return (
        <Card className="Card sm:col-offset-3 sm:col-6 sm" title={
            <div className="flex">
                <span className="mr-auto">{AuthUtils.current().language === "en" ?
                    data.titleEn : data.titleAr}</span>
                <Tag style={{fontSize: '1.5rem', fontWeight: '700'}} rounded severity={'success'}
                    value={data.partner?.partnerName} />
            </div>
        } subTitle={data.startDate ?
            DateUtils.format(data.startDate!, "date") + " - " + DateUtils.format(data.endDate!, "date") : ''}>
            {/* {
                data.extraItems && data.extraItems.length > 0 &&
                <Carousel value={data.extraItems ?? []} numVisible={1} numScroll={1} itemTemplate={postTemplate}
                    className="custom-carousel col-12" circular
                    autoplayInterval={data.extraItems.length > 1 ? 3000 : 0} />
            } */}
          {
                data.extraItems && data.extraItems.length > 0 &&
                <div className="text-center">
                <img style={{maxHeight: '250px', maxWidth:'100%' }}
                src={data.extraItems ? data.extraItems[0].fileId : ''} alt={""} className="shadow-2" />
                </div>
            }

            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere', textAlign: 'justify' }}>{AuthUtils.current().language === "en" ? data.descriptionEn : data.descriptionAr}</p>
        </Card>
    )
}

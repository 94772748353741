import React from "react";
import { TawreedMessageDto } from "../../../domain";
import { Ripple } from "primereact/ripple";
import { DateUtils } from "../../../../../helpers/date";
import { useHistory } from "react-router";
import { Routes } from "../../../../../router";

import { GlobalContext } from "../../../../../context";
import { AuthUtils } from "../../../../auth/domain";

export type NotificationsDropdownItemProps = {
    /**
     *
     */
    notification: TawreedMessageDto;

    onMarkAsRead: () => Promise<void>;
    onItemClick: () => Promise<void>;
}

export const NotificationsDropdownItem: React.FC<NotificationsDropdownItemProps> = (props) => {

    const history = useHistory();
    const { application } = React.useContext(GlobalContext);

    const onMarkAsRead = () => {
        if (props.onMarkAsRead) {
            props.onMarkAsRead().finally();
        }
    };

    const onClick = () => {
        if (props.notification.unread) {
            onMarkAsRead();
        }
        switch (props.notification.typeCode) {
            case "ORDER":
                props.onItemClick();
                history.push(Routes.SalesOrdersDetails.replace(':id', props.notification.recordId.toString()));
                break;
            case "RETURN_ORDER":
                props.onItemClick();
                history.push(Routes.SalesOrdersReturnedDetails.replace(':id', props.notification.recordId.toString()));
                break;
            case "CART":
                props.onItemClick();
                history.push(Routes.SalesCartsDetails.replace(':id', props.notification.recordId.toString()));
                break;
            case "PROMOTION":
                props.onItemClick();
                history.push(Routes.PostView.replace(':id', props.notification.recordId.toString()));
                break;
            // case "CUSTOMER":
            //     history.push(Routes.PartnersCustomersDetails.replace(':id', props.notification.recordId.toString()));
            //     break;
        }
    }

    return (
        <div className={`notification-item ${props.notification.unread ? 'unread bg-gray-100' : 'read'} p-ripple pt-2 pb-1 col-12 hover:bg-gray-200 bg-gray-50`} onClick={() => onClick()} style={{height: '7rem'}}>
            <Ripple />

            <div className={`notification-item-inner cursor-pointer grid`}>
                <div className="col-3">
                    {/* <i className={`icon ${PrimeIcons.BELL}`} /> */}
                    {
                        props.notification.logoContent ? 
                        <img className={`flex-shrink-0 border-circle mt-1 ml-3 mb-1 notification-item-image`} src={props.notification.logoContent} alt=""/>
                        :
                        <img className={`flex-shrink-0 border-circle mt-1 ml-3 mb-1 notification-item-image`} src={application.logo[AuthUtils.current().language]['light']} alt={application.title} />
                    }
                </div>
                <div className="col-9">
                    <div className="font-bold">{props.notification.subject}</div>
                    
                    {
                        props.notification.timeAgo ? 
                        <small className="text-blue-500">{props.notification.timeAgo}</small>
                        :
                        <small className="text-blue-500">{DateUtils.format(props.notification.sendDate!, "datetime")}</small>
                    }
                    <div className="" style={{whiteSpace:'pre-wrap',overflowWrap: 'break-word', textAlign:'justify'}}>
                        {props.notification.message.length > 80 ? props.notification.message.slice(0,80) + " ....."
                        : props.notification.message}</div>
                </div>
            </div>
        </div>
    );
}
